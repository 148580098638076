<template>
	<div class="o-main">
		<el-card class="setCard">
			<div class="flex">
				<el-menu :default-active="activeIndex" class="el-menu-demo setBorder"  :active-text-color="textColor" mode="horizontal" @select="chooseCurrentType">
					<el-menu-item index="0">全部</el-menu-item>
					<el-menu-item index="1">机票</el-menu-item>
					<el-menu-item index="2">酒店</el-menu-item>
					<el-menu-item index="3">火车票</el-menu-item>
					<el-menu-item index="4">需求单</el-menu-item>
					<el-menu-item index="5">用车</el-menu-item>
				</el-menu>
			</div>
		</el-card>
		<div class="flex between marT10 ">
			<el-card style="width: 100%;">
				<div class="flex between setSelect borderb-f2">
					<div class="flex flexStart "  v-if="titleType!=4">
						<el-select v-model="dataParams.productType" class="selectOrder" placeholder="全部行程" @change="chooseProductType" v-if="titleType==0">
						    <el-option
						      v-for="item in productTypeList "
						      :key="item.id"
						      :label="item.text"
						      :value="item.id">
						    </el-option>
						</el-select>
						<el-select v-model="dataParams.tripType" class="selectTriType" placeholder="公 / 私" @change="chooseTripType" v-if="titleType!=4">
						    <el-option
						      v-for="item in tripTypeList"
						      :key="item.id"
						      :label="item.text"
						      :value="item.id">
						    </el-option>
						</el-select>
						<el-select v-model="dataParams.dateType" class="selectDate" @change="chooseDateType" placeholder="请选择日期类型" v-if="titleType!=5">
						    <el-option
						      v-for="item in dateTypeList"
						      :key="item.id"
						      :label="item.text"
						      :value="item.id">
						    </el-option>
						</el-select>
						<div class="marL25 line-h14 setSwitch"  v-if="titleType != 4">
							<el-switch
								class="colorB3"
								active-text="查看其他同事"
								v-model="otherColleagues"
								@change="isOtherChange" 
								>
							</el-switch>
						</div>
					</div>
					<div v-if="titleType==4" class="xqdBtn">
						<div v-if="gkfsparams==2">
							<el-button type="primary" v-if="inTkEdit!=0" @click="addInTkNeed">新增机票需求单</el-button>
							<el-button type="primary" v-if="inHtEdit!=0" @click="addInHtNeed">新增酒店需求单</el-button>
						</div>
						<div v-if="gkfsparams!=2">
							<el-button type="primary" v-if="gkfsparams==1 && inTkEdit!=0" @click="addInTkNeed">新增机票需求单</el-button>
							<el-button type="info" disabled v-if="gkfsparams==1 && inTkEdit==0">新增机票需求单</el-button>
							<el-button type="primary" v-if="gkfsparams==1 && inHtEdit!=0" @click="addInHtNeed">新增酒店需求单</el-button>
							<el-button type="info" disabled v-if="gkfsparams==1 && inHtEdit==0">新增酒店需求单</el-button>
						</div>
					</div>
					<el-button type="text" class="setButton" @click="setReset">清空</el-button>
					
				</div>
				<div class="flex between textLeft setDateInput marT22">
					<div class="flex wrap flexStart">
						<div class="w140 marR20">
							<p class="marB9 setFont">起始日期</p>
							<el-date-picker
							    v-model="dataParams.dateStart"
								value-format="yyyy-MM-dd"
								prefix-icon="false"
							    type="date"
								:clearable="false"
								@change="getStartDate"
							    placeholder="选择开始日期">  
							</el-date-picker>
						</div>
						<div  class="w140 marR20">
							<p  class="marB9 setFont">结束日期</p>
							<el-date-picker
							    v-model="dataParams.dateEnd"
								:clearable="false"
								value-format="yyyy-MM-dd"
								prefix-icon=" "
							    type="date"
								@change="getNowDate"
							    placeholder="选择结束日期">  
							</el-date-picker>
						</div>
						<div class="w140 marR20" v-if="titleType!=4 && titleType!=5">
							<p  class="marB9 setFont">出行人</p>
							<el-input v-model="dataParams.psgName" placeholder="出行人姓名" @change="getChangeCxrName"></el-input>
						</div>
						<div class="w150 marR20 setOrderInput"  v-if="titleType!=4">
							<p  class="marB9 setFont">{{titleType!=3?'订单编号':'乘车人/订单号'}}</p>
							<el-input v-model="dataParams.orderNo" :placeholder="titleType!=3?'订单编号':'乘车人/订单号'"  @change="getChangeOrderNo"></el-input>
						</div>
						<div class="w140 marR20" v-if="titleType==2">
							<p  class="marB9 setFont">酒店名称</p>
							<el-input v-model="dataParams.jdmc" placeholder="酒店名称"   @change="getChangeJdmc"></el-input>
						</div>
						<div class="w140 marR20" v-if="titleType==5">
							<p  class="marB9 setFont">乘车人</p>
							<el-input v-model="dataParams.empName" placeholder="乘车人"  @change="getChangeEmpName"></el-input>
						</div>
					</div>
					<div class="flex reColumn">
						<el-button class="searchBtn"  type="primary" plain @click="queryOrderType">
							<span  class="el-icon-refresh-right rotateZ" :style="{transform: 'rotate('+countRotate+'deg)'}"></span>
						</el-button>
					</div>
					
				</div>

			</el-card>
		</div>
		<div class="setTabPaneCard" style="margin-top: 10px;">
			<el-tabs type="border-card"  v-model="allType" @tab-click="getChooseTable" >
				<el-tab-pane :label="item.title" v-for="(item, index) in tabPaneList" :key="index">
					<order-list 
						v-loading = "loading"
						element-loading-background = "rgba(255, 255, 255, .8)"
						element-loading-text = "加载中，请稍后..."
						element-loading-spinner = "el-icon-loading" 
						:orderList="orderList" 
						:titleType="titleType" 
						:tabIndex="dataParams.findType" 
						:pageInfo="pageInfo" 
						@choosePageNum="getChoosePageNum" 
						@reSetOrderList="reSetOrderList"
						 :params20032="params20032"
						 ></order-list>
				</el-tab-pane>
			</el-tabs>
			
		</div>
		
	</div>
</template>

<script>
	import {mapState, mapMutations, mapGetters} from 'vuex';
	import OrderList from './orderComponents/OrderList';
	export default{
		name: 'Order',
		data(){
			return {
				activeIndex:'0',
				allType: '0', // 全部订单
				titleType:0,
				rotate: 0,
				loading:false, // 加载效果
				textColor:'#1C83ED', 
				isEmpty: false,  // 订单为空下显示
				otherColleagues: false, // 查看其他同事
				pickerStartOptions:{
					startDate:()=>{
						const   start = new Date();
					            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
							
								return start
					}
				},
				dataParams:{
					count: 10,
					orderNo:'', // 订单编号
					dateType:'', // 日期类型
					dateStart:'', //开始日期
					dateEnd:'', // 结束日期
					findType: 1, // 业务类型
					findScope: 1, // 查看其他同事true 时传2
					tripType:'',  // 因公因私
					productType:'', // 出行类型
					psgName:'', // 出行人姓名
					pageNum: 1,
					jdmc:'', // 酒店名称
					empName:'', // 乘车人
					linkman:'', // 联系人
					jsjcszm:'',
				},
				pageInfo:{
					count: 10,
					pageNum: 1,
					total:0,
				},
				orderList:[],
				productTypeList: [
					{
						text: '全部行程',
					},
					{
						text: '国内机票',
						id: '10901',
					},
					{
						text: '国际机票',
						id: '10902',
					},
					{
						text: '酒店',
						id: '10903',
					},
					{
						text: '火车票',	
						id: '10904',
					},
					{
						text: '用车',
						id: '10906',
					}
				], // 全部行程类型
				tripTypeList: [
					{
						text: '公/私',
					},
					{
						text: '因公',
						id:'1',
					},
					{
						text: '因私',
						id: '2',
					}
				], //因公因私
				dateTypeList: [
					{
						text: '预订日期',
						id: '1',
					},
					{
						text: '出发/入住日期',
						id: '2',
					},
				], //日期列表
				tabPaneList:[
					{
						title: '全部',
						id: '1',
					},
					{
						title: '待支付',
						id: '2',
					},
					{
						title: '未出行',
						id: '3',
					},
					{
						title: '退改签',
						id: '4',
					}
				],
			}
		},
		components: {
			'order-list': OrderList,
		},
		created(){
			let color = this.$common.localget('colorTheme');
			if(color){
				this.textColor = color;
			}
			this.chooseDateType();
			this.getNowDate();
			this.getStartDate();
		},
		computed:{
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),	
			// 1 置灰 2隐藏 模块控制
			gkfsparams(){
				return this.getVipParamterByParNo(20029,3);
			},	
			// 国际机票需求单
			inTkEdit(){
				return this.getVipParamterByParNo(20008,3)
			},
			// 国际酒店需求单
			inHtEdit(){
				return this.getVipParamterByParNo(20010,3)
			},
			// 机票是否提交BPM机票更改申请流程
			params20032(){
				return this.getVipParamterByParNo(20032,1)
			},
			//计算旋转度数
			countRotate(){
				return this.rotate
			},
		},
		mounted() {
			// 火车票订单 下单成功进入支付状态 到订单列表待支付
			var payObj = this.$route.query || {};
			this.allType = payObj.toBePaid || '0';
			let self = this;
			if(payObj.findTypeIndex){
				this.allType = payObj.findTypeIndex?payObj.findTypeIndex:'0';
				// 退改签处理，
				let obj = {
						index: payObj.findTypeIndex
				}
				this.getChooseTable(obj)
			}
			this.queryOrderType();
		},
		methods: {
			// 选中当前类型选中 机酒火
			chooseCurrentType(e){
				// 点击当前类型相同时，不在触发方法 
				if(e==this.titleType){
					
				}else{
					// 切换不同类型 重置分页
					this.titleType = e;
					this.allType = '0';
					this.dataParams.productType= e==0?'':e;
					if(this.pageInfo.pageNum!=1){
						this.pageInfo.pageNum = 1
					}			
				} 
				
				// 日期重置   
				// this.getNowDate();
				// this.getStartDate();
				if(this.titleType != 4){
					this.tabPaneList=[
						{title: '全部',id: '1',},
						{title: '待支付',id: '2',},
						{title: '未出行',id: '3',},
						{title: '退改签',id: '4',}
					];
					if(this.titleType == 2){
						this.dateTypeList = [
							{text: '预订日期',id: '1',},
							{text: '入住日期',id: '2',},
						]	
					}else{
						this.dateTypeList = [
							{text: '预订日期',id: '1',},
							{text: '出发日期',id: '2',},
						]
					}
					
				}else{
					this.tabPaneList = [
						{title: '国际机票需求单',id: '1',},
						{title: '酒店需求单',id: '2',}
					]
				}
				
				
				this.getChooseTable()
			},
			// 获取出行人 重置分页
			getChangeCxrName(){
				this.resetPageNum()
			},
			// 获取订单号 重置分页
			getChangeOrderNo(){
				this.resetPageNum()
			},
			// 获取酒店名称 重置分页
			getChangeJdmc(){
				this.resetPageNum()
			},
			// 获取乘车人 重置分页
			getChangeEmpName(){
				this.resetPageNum()
			},
			// 重置分页
			resetPageNum(){
				this.dataParams.pageNum = 1;
			},
			// 是否查看其他同事
			isOtherChange() {
				this.dataParams.pageNum = 1;
				if(this.otherColleagues){
					this.dataParams.findScope = 2;
					this.queryOrderType();
				}else{
					this.dataParams.findScope = 1;
					this.queryOrderType();
				}
			},
			// 新增国际机票需求单
			addInTkNeed(){
				this.$router.push('/yjTravel/book/inTkRouter/inTkEdit')
				
			},
			// 新增国际酒店需求单
			addInHtNeed(){
				this.$router.push('/yjTravel/book/inHtRouter/inHtEdit')
				
			},
			// 行程类型查找
			chooseProductType(e){
				this.dataParams.productType = e ;
				this.dataParams.pageNum = 1;
				this.queryOrderType();
			},
			// 因公因私查找
			chooseTripType(e){
				this.dataParams.tripType = e ;
				this.dataParams.pageNum = 1;
				this.queryOrderType();
			},
			// 默认选择类型
			chooseDateType(e){
				this.dataParams.pageNum = 1;
				if(e){
					this.dataParams.dateType = e;
				}else{
					this.dataParams.dateType = this.dateTypeList[0].id;
				}
			},
			// 清空选项，重置
			setReset(){
				this.chooseDateType();
				this.getNowDate();
				this.getStartDate();
				this.dataParams.tripType = '';
				this.dataParams.productType = '';
				this.dataParams.psgName = '';
				this.dataParams.pageNum = 1;
				this.dataParams.count = 10;
				this.dataParams.findType = 1;				
				this.dataParams.findScope = 1;
				this.dataParams.jdmc = '';
				this.otherColleagues = false;
				this.dataParams.orderNo = '';
			},
			// 获取今天日期
			getNowDate(e) {
				if(e){
					this.dataParams.dateEnd = e;
					if(this.dataParams.dateStart && this.dataParams.dateEnd){
						if(this.dataParams.dateStart>this.dataParams.dateEnd){
							this.dataParams.dateEnd = this.dataParams.dateStart;
						}
					}
					
				}else{
					let now = new Date();
					let year = now.getFullYear(); //得到年份
					let month = now.getMonth(); //得到月份
					let date = now.getDate(); //得到日期
					month = month + 1;
					month = month.toString().padStart(2, "0");
					date = date.toString().padStart(2, "0");
					let defaultDate = `${year}-${month}-${date}`;
					this.dataParams.dateEnd = defaultDate;
				}
				this.dataParams.pageNum = 1;	
			},
			// 获取起始日期
			getStartDate(e){
				if(e){
					this.dataParams.dateStart = e;	
					if(this.dataParams.dateStart && this.dataParams.dateEnd){
						if(this.dataParams.dateStart>this.dataParams.dateEnd){
							this.dataParams.dateEnd = this.dataParams.dateStart;
						}
					}
					
				}else{
					
					// 获取时间
					var nowDate = new Date();
					// 获取当前时间
					var date = new Date(nowDate);
					// 获取一周时间
					date.setDate(date.getDate() - 7);
					var seperator1 = '-'
					var year = date.getFullYear()
					var month = date.getMonth() + 1
					var strDate = date.getDate()
					if (month >= 1 && month <= 9) {
					    month = '0' + month
					}
					if (strDate >= 0 && strDate <= 9) {
					    strDate = '0' + strDate
					}
					var formatDate = year + seperator1 + month + seperator1 + strDate;
					this.dataParams.dateStart = formatDate;	
				}
				this.dataParams.pageNum = 1;	
			},
			
			// tab选项
			getChooseTable(tab){	
				// 除需求单外   不同业务类型 全部订单 1 全部  2 待支付  3 未出行 4 退改签 默认查全部
				// 需求单   国际需求单 1机票 2酒店
				let index = 1; 
				if(tab){
					index = (parseInt(tab.index)-0)+1;
				}
				this.dataParams.findType = index;
				this.dataParams.pageNum = 1;
				this.queryOrderType();
			},
			// 查询订单类型
			queryOrderType(){
				
				this.rotate = (360 + this.rotate);
				let obj = {
							1:{productType: '10901',},
							2:{productType: '10903',},
							3:{productType: '10904',},
							5:{productType: '10906',}
						}
				// 后期 用车需要退改签与全部，那么久不用	this.titleType == 5条件	
				if(this.titleType != 4){
					if(this.titleType == 1 || this.titleType == 2 || this.titleType == 3 || this.titleType == 5){
						this.dataParams.productType = obj[this.titleType].productType;
					}
					this.getAllOrder()
				}

				if(this.titleType && this.titleType == 4){
					if(this.dataParams.findType==2){
						this.getHtNeedOrderList();
					}else{
						this.getTkNeedOrderList();
					}	
				}
			},
			// 获取全部订单
			getAllOrder(){
				let queryParams = {};
					queryParams = {
						count: this.dataParams.count,
						orderNo: this.dataParams.orderNo, // 订单编号
						dateType: this.dataParams.dateType, // 日期类型
						dateStart: this.dataParams.dateStart, //开始日期
						dateEnd: this.dataParams.dateEnd, // 结束日期
						findType: this.dataParams.findType, // 业务类型
						findScope:  this.dataParams.findScope, // 查看其他同事true 时传2
						tripType: this.dataParams.tripType,  // 因公因私
						productType: this.dataParams.productType, // 出行类型
						psgName: this.dataParams.psgName, // 出行人姓名
						pageNum:  this.dataParams.pageNum,
					}
				this.loading = true;	
				this.$conn.getConn('tkOrder.totalOrderList')(queryParams,(res)=>{
					let data = res.data || {};
					this.pageInfo = {
							count: data.count,
							pageNum: data.pageNum,
							total:data.total,
					}
					this.orderList = data.list; 
					this.loading = false;
				},(err)=>{
					this.loading = false;
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 获取国际需求单列表
			getTkNeedOrderList(){
				this.loading = true;	
				let queryParams = {};
					queryParams = {
						count: this.dataParams.count,
						dateStart: this.dataParams.dateStart, //开始日期
						dateEnd: this.dataParams.dateEnd, // 结束日期
						pageNum:  this.dataParams.pageNum,  				
					};
				this.$conn.getConn('tkInter.tkNeedOrderList',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					this.orderList = data.list || [];
					this.pageInfo = {
							count: data.count,
							pageNum: data.pageNum,
							total:data.total,
					}
					this.loading = false;	
				}).catch((err)=>{
					this.loading = false;
					this.$message({
						type: 'error',
						message: err.tips
					})
				})	
			},
			// 获取国际酒店订单
			getHtNeedOrderList(){
				this.loading = true;
				let queryParams = {};
					queryParams = {
						count: this.dataParams.count,
						lookPriceDateStart: this.dataParams.dateStart, //开始日期
						lookPriceDateEnd: this.dataParams.dateEnd, // 结束日期
						pageNum:  this.dataParams.pageNum,  				
					};
					this.$conn.getConn('hotelOrder.hotelNeedOrderList')(queryParams).then((res)=>{
						let data = res.data || {};
						this.orderList = data.list || [];
						this.pageInfo = {
								count: data.count,
								pageNum: data.pageNum,
								total:data.total,
						}
						this.loading = false;
					}).catch((err)=>{
						this.loading = false;
						this.$message({
							type: 'error',
							message: err.tips
						})
					})	
			},		
			
			// 选中的当前页
			getChoosePageNum(e){
				this.dataParams.pageNum = e;
				this.queryOrderType();
			},
			// 重置订单列表
			reSetOrderList(number,tabIndex){
				if(tabIndex){
					this.dataParams.findType = tabIndex;
				}else{
					this.dataParams.findType = 1;
				}
				//返回到不同模块 num
				if(number!=4){	
					this.getAllOrder();
				}else{
					if(this.dataParams.findType==2){
						this.getHtNeedOrderList();
					}else{
						this.getTkNeedOrderList();
					}
					
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	
	.o-main{
		font-family: '微软雅黑';
		.el-card.is-always-shadow{
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
		}
		.el-tabs--border-card{
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
		}
		.setBorder{
			/deep/ .el-menu-item{
						padding: 0;
						margin: 0 20px;
						height: 54px;
						line-height: 54px;
						font-size: 15px;
						font-weight: 400;
						color: #424242;
					}
			.is-active{
				font-weight:bold;
			}		
		}
		/deep/ .el-input__inner{
			height: 40px;
			line-height: 40px;
			border-radius: 2px;
			cursor: pointer;
			border: none;
		}
		/deep/	.el-menu--horizontal{
				border-bottom: none;
			}
		.setTabPaneCard{
			/deep/ .el-card__body{
				padding: 0;
			}
			/deep/ .el-tabs__content{
				padding: 0;
			}
			
		}	
		.setCard{
			/deep/ .el-card__body{
				padding: 0 20px;
			}
		}
		
		.setSwitch{
		
			/deep/ .el-switch__core{
				height:8px;
			}
			/deep/ .el-switch__core:after{
				top: -6px;
				left: -2px;
				border: 1px solid #DCDFE6;
				border-radius: 100%;
			 }
			/deep/ .el-switch.is-checked .el-switch__core::after {
			    left: 100%;
			    margin-left: -17px;
			}
		}
		.xqd{
			.el-button{
				
			}
		}
		.setButton{
			font-size: 14px;
			font-weight: 400;
			color: #B6B6B6;
			padding: 0;
			:hover{
				color: #00A7FA;
			}
		}
		.setSelect{
			padding-bottom: 10px;
			.el-input{
				height: 14px;
				line-height: 14px;
			}
			/deep/ .el-input--small .el-input__icon {
			    line-height: 14px;
			}
			/deep/ .el-input__inner{
				cursor: pointer;
				padding-left: 0;
				height: 14px;
				font-size: 14px;
				line-height: 14px;
			}
			/deep/ input::-webkit-input-placeholder {
				color: #424242;
			  }
			/deep/ input::-moz-input-placeholder {
				color: #424242;
			  }
			/deep/ input::-ms-input-placeholder {
				color: #424242;
			  }
		}
		.selectOrder{
			width: 90px;
			margin-right:10px;
		}
		.selectTriType{
			width: 90px;
			margin-right:10px;
		}
	
		.setDateInput{
			.el-input{
				width: 140px;
				
			}
			/deep/ .el-input__inner{
				width: 140px;	
				background: #F5F5F5;
				padding-left: 15px;
				cursor: pointer;
			}
			/deep/ input::-webkit-input-placeholder {
				color: #CCCCCC;
			  }
			/deep/ input::-moz-input-placeholder {
				color: #CCCCCC;
			  }
			/deep/ input::-ms-input-placeholder {
				color: #CCCCCC;
			  }
		}
		.setOrderInput{
			.el-input{
				width: 150px;
			}
			/deep/ .el-input__inner{
				cursor: pointer;
				width: 150px;
			}
		}
		.selectDate{
			width: 120px;
		}
		.searchBtn{
			padding:0 10px;
			height:40px;
			font-size: 18px; 
			font-weight: bold;
			.rotateZ{
				transition: all 0.5s ease;
			}
		}
		.flex{
			display: flex;
		}
		.reColumn{
			 flex-direction:column-reverse;
		}
		.flexStart{
			justify-content:flex-start;
		}
		.wrap{
			flex-wrap: wrap;
		}
		.between{
			justify-content: space-between;
		}
		.marT10{
			margin-top: 10px;
		}
		.marT22{
			margin-top: 22px;
		}
		.marL25{
			margin-left: 25px;
		}
		.marB9{
			margin-bottom: 9px;
		}
		.marR20{
			margin-right: 20px;
		}
		.w140{
			width: 140px;
		}
		.w150{
			width: 150px;
		}
		.w180{
			width: 180px;
		}
		.w190{
			width: 190px;
		}
		.w300{
			width: 300px;
		}
		.w400{
			width: 400px;
		}
		.w480{
			width: 480px;
		}
		.w690{
			width: 690px;
		}
		.w710{
			width: 710px;
		}
		.line-h14{
			line-height: 14px;
		}
		.line-h32{
			line-height: 32px;
		}
		.line-h54{
			line-height: 54px;
		}
		.textLeft{
			text-align: left;
		}
		.textRight{
			text-align: right;
		}
		.setFont{
			font-size: 12px;
			font-weight: 400;
			color: #CCCCCC;
			line-height: 12px;
		}
		.colorB{
			color: #BBBBBB;
		}
		.colorB6{
			color: #B6B6B6;
		}
		.colorB3{
			color: #B3B3B3; 
		}
		.color409eff{
			color: #409eff;
		}
		.color42{
			color: #424242;
		}
		.setInput{
			/deep/ .el-input__inner{
				width: 150px;
				border: none;
				border-radius: 32px;
				background-color: #F7F7F7;
				margin:16px 0;
				cursor: pointer;
			}
			/deep/ input::-webkit-input-placeholder {
				color: #B3B3B3;
				text-align: center;
			  }
			/deep/ input::-moz-input-placeholder {
				color: #B3B3B3;
				text-align: center;
			  }
			/deep/ input::-ms-input-placeholder {
				color: #B3B3B3;
				text-align: center;
			  }
		}
		.borderb-f2{
			border-bottom: 1px solid #F2F2F2;
		}
		.pointer{
			cursor: pointer;
		}
	}
</style>
